<template>
  <div class="activities">
    <page-header title="Portail familles" icon="fa-solid fa-house-signal"></page-header>
    <b-row>
      <b-col>
        <div>
          <ul class="app-menu">
            <li v-if="hasAllPerms(['portal.view_', 'youth.view_seanceinscription'])">
              <router-link :to="{ name: 'confirm-inscriptions', }">
                <i class="fa fa-check"></i> Inscriptions à confirmer
              </router-link>
            </li>
            <li v-if="hasAllPerms(['portal.view_', 'youth.view_seanceinscription'])">
              <router-link :to="{ name: 'inscription-confirmations-list', }">
                <i class="fa fa-history"></i> Liste des inscriptions acceptées ou refusées
              </router-link>
            </li>
            <li v-if="hasPerm('portal.view_account')">
              <router-link :to="{ name: 'family-portal-members', }">
                <i class="fa fa-users"></i> Comptes du Portail famille
              </router-link>
            </li>
            <li v-if="hasOnePerm(['portal.view_youthhomeserviceopening', 'portal.view_usermessage'])">
              <router-link :to="{ name: 'family-portal-openings', }">
                <i class="fa fa-calendar"></i> Périodes d'inscription et messages aux familles
              </router-link>
            </li>
            <li v-if="hasPerm('certificates.view_certificate')">
              <router-link :to="{ name: 'certificates', }">
                <i class="fa fa-certificate"></i> Justificatifs
              </router-link>
            </li>
            <li v-if="hasAllPerms(['activities.view_coreactivityinscription', 'cms.view_activityarticle'])">
              <router-link :to="{ name: 'confirm-portal-data', }">
                <i class="fa fa-home-user"></i> Vérification des données saisies par les familles
              </router-link>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'family-portal-home',
  mixins: [BackendMixin],
  components: {
    PageHeader,
  },
  props: {
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="less">
</style>
